'use client'

import useAuth from '@/store/auth'
import useFpd from '@/store/fpd'
import useGlobal from '@/store/global'
import { useReCaptcha } from 'next-recaptcha-v3'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { pushCustomEvent } from '@/utils/logAnalyticsEvent'
import useMeta from '@/store/meta'

export default function CCP_ReadPostEvent({ post = null }) {
  const { closeSnackbar } = useSnackbar()
  /**
   * fpd value
   */
  const { executeRecaptcha } = useReCaptcha()
  const { userMe } = useAuth()
  const postID = post?._id
  const [isAlreadyShowSnackbar, setIsAlreadyShowSnackbar] = useState(false)
  const { getCenter, getTaxonomy } = useMeta()

  /**
   * Register
   */
  const hasReadToEarn = post?.fpd_read_post_point_to_earn
  const hasFpdTag = post?._terms?.find((tag) => tag.taxonomy === 'fpd_tag')
  const shouldLogEvent = (hasReadToEarn || hasFpdTag) && postID

  /**
   * Guest
   */
  useEffect(() => {
    if (!hasReadToEarn || userMe !== undefined) return
    const handleScroll = () => {
      if (isAlreadyShowSnackbar) return
      setIsAlreadyShowSnackbar(true)
      // set delay for point action
      setTimeout(() => {
        // set guest snackbar
        closeSnackbar()
        useGlobal.setState({
          isShowGuestSnackbar: true,
        })
      }, 10000)
    }
    window.addEventListener(
      'scroll',
      isAlreadyShowSnackbar ? () => {} : handleScroll
    )
    return () => {
      window.removeEventListener('scroll', handleScroll)
      useGlobal.setState({
        isShowGuestSnackbar: false,
      })
    }
  }, [isAlreadyShowSnackbar, hasReadToEarn, userMe])

  /**
   *  User [Log event]
   */
  // When user start scrolling the page => wait  10 seconds then do something
  const logEvent = async () => {
    const token = await executeRecaptcha('fpd_read_post')
    const res = await useFpd.getState().logEvent({
      event_name: 'READ_POST',
      post_id: postID,
      token,
    })
    await useAuth.getState().getUserMe()
  }
  useEffect(() => {
    if (!shouldLogEvent || !userMe) return
    const handleScroll = () => {
      if (isAlreadyShowSnackbar) return
      setIsAlreadyShowSnackbar(true)
      // set delay for point action
      setTimeout(() => {
        // earn point
        logEvent()
      }, 10000)
    }
    window.addEventListener(
      'scroll',
      isAlreadyShowSnackbar ? () => {} : handleScroll
    )
    return () => window.removeEventListener('scroll', handleScroll)
  }, [isAlreadyShowSnackbar, shouldLogEvent, userMe])

  useEffect(() => {
    // log ga4 event 'read_content'
    //check if post type is article
    if (post?._type !== 'article') return

    //get term
    const articleCategory = post?._terms?.find(
      (x) => x.taxonomy === 'article_category'
    )?.term_id
    const type = getTaxonomy(articleCategory, '_id', 'article_category')?._slug

    //get center from id
    const relatedCenterId =
      post?.related_center?.length && post?.related_center[0]
    const center = getCenter(relatedCenterId, '_id')?._slug

    const readDataLayer = {
      event: 'read_content',
      author: post?.author,
      center,
      type,
    }
    setTimeout(() => {
      pushCustomEvent(readDataLayer)
    }, 3000)
  }, [])

  return <></>
}
